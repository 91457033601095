<template>
  <AutoComplete
      v-model="internalModel"
      :suggestions="filteredEquipments"
      @complete="searchEquipment"
      @item-select="onItemSelect"
      @clear="onItemClear"
      :forceSelection="forceSelection"
      field="name"
      :minLength=3
      placeholder="Search equipment by serial no & desc ..."
  >
    <template #item="{ item, index }">
      <div class="autocomplete-row">
        <div class="autocomplete-serial-number" :title="item.serial_number">
          {{ item.serial_number }}
        </div>
        <div class="autocomplete-name" :title="item.name">{{ item.name }}</div>
      </div>
    </template>
  </AutoComplete>
</template>

<script>


export default {
  props: {
    value: {
      type: [Object, String],
      default: () => {
        return ""
      }
    },
    forceSelection: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    internalModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (typeof newValue === 'string' && !this.isEquipmentInList(newValue)) {
          const customValue = {equipment_id: null, name: newValue};
          this.$emit('input', customValue);
        } else {
          this.$emit('input', newValue);
        }
      }
    }
  },
  data() {
    return {
      filteredEquipments: [],
    };
  },
  methods: {
    async searchEquipment(event) {
      if (!event.query.trim()) return;

      try {
        const queryParams = this.buildQueryParams(event.query);
        const response = await this.EquipmentService.getEquipment({filterOptions: queryParams});
        this.filteredEquipments = response;
      } catch (error) {
        console.error("Error fetching equipment:", error);
      }
    },
    buildQueryParams(searchTerm) {
      return {
        searchTerm,
        noPaging: "True",
        select: 'product_code, equipment_id, description, product_type, name, serial_number '
      };
    },
    isEquipmentInList(name) {

      // return this.filteredEquipments.some(equipment => equipment.name === name);
    },
    onItemSelect(event) {
      this.$emit('equipmentSelected', event);
    },
    onItemClear(event) {
      this.$emit('equipmentCleared', event);
    },
  }
};
</script>

<style>
.autocomplete-row {
  display: flex;
  justify-content: space-between;
}

.autocomplete-name,
.autocomplete-serial-number {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-serial-number {
  width: 40%;
}

.autocomplete-name {
  width: 60%;
}

.p-autocomplete-panel {
  max-width: 100%;
  width: auto;
}

.p-autocomplete-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
